<template>
    <div>
        <v-row dense>
            <v-col cols="4">
                <select-pos-model v-model="id_pos" :label="$t('labels.pos')" @change="onPosChange"
                    :disabled="basket.id"></select-pos-model>
            </v-col>
            <v-col cols="8">
                <input-qr-scan-model ref="inputQr" v-model="code" :label="label"
                    @keyupEnter="inputScan"></input-qr-scan-model>
            </v-col>
        </v-row>

        <div class="mt-3">
            <v-alert color="secondary" text dark class="text-center mb-0">
                <span class="text-h6">{{ $t('labels.basket') }}: </span>
                <span class="text-h5">{{ basket.code }}</span>
            </v-alert>
            <v-alert color="error" text dark class="text-center mb-0">
                <span class="fs-16">{{ $t('labels.current_position') }}: </span>
                <span class="text-h5">{{ location.cell_id || location_code }}</span>
            </v-alert>
            <!-- <v-alert color="success" text dark class="text-center mb-0">
                <span class="fs-16">{{ $t('labels.position_suggest') }}: </span>
                <span class="text-h5">{{ location.cell_id || location_code }}</span>
            </v-alert> -->
        </div>

        <div class="mt-3">
            <v-alert color="primary" text dark dense class="font-weight-medium text-center mb-0">
                UID
            </v-alert>
            <div>
                <v-simple-table class="table-padding-2-no-top">
                    <tbody>
                        <tr v-for="uid in items" :key="`u_${uid}`">
                            <td class="text-center">{{ uid }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </div>
        </div>
    </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
    name: "SetPosition",
    data: () => ({
        id_pos: null,
        code: null,
        isLoading: false,
        basket: {},
        location: {},
        location_code: null,
        items: [],
        step: 1,
    }),
    watch: {},
    computed: {
        label() {
            switch (this.step) {
                case 1:
                    return this.$t('labels.basket_code')
                case 2:
                    return this.$t('labels.position')
                case 3:
                    return this.$t('labels.position_or_basket_finish')
                case 4:
                    if (this.items.length > 0) {
                        return this.$t('labels.uid_position_or_basket_finish')
                    } else {
                        return this.$t('labels.uid')
                    }
                default:
                    return this.$t('labels.code')
            }
        },
    },
    mounted() {
        if (window.me && window.me.working && window.me.working.pos_stowing && window.me.working.pos_stowing_item) {
            const working = JSON.parse(window.me.working.pos_stowing_item)
            this.basket = { ...working.basket }
            this.location = { ...working.location }
            this.items = [...working.items]
            this.location_code = working.location_code
            this.step = working.step
            this.id_pos = working.id_pos
        }
    },
    methods: {
        onPosChange() {
            this.focusInputQr()
            this.resetValue()
        },
        focusInputQr() {
            this.$refs.inputQr.focusInput()
        },
        resetValue() {
            this.basket = {}
            this.location = {}
            this.location_code = null
            this.items = []
            this.step = 1
        },
        stowingSetWorking() {
            this.updateWorking({
                type: 'pos_stowing',
                item: JSON.stringify({
                    basket: this.basket,
                    location: this.location,
                    location_code: this.location_code,
                    items: this.items,
                    step: this.step,
                    id_pos: this.id_pos,
                })
            })
        },
        inputScan() {
            if (!this.code) {
                return false
            }

            switch (this.step) {
                case 1:
                    return this.scanBasket()
                case 2:
                    return this.scanLocation()
                case 3:
                    if (this.code == this.basket.code) {
                        return this.finishBasket()
                    }
                    return this.getLocationOrUid()
                case 4:
                    if (this.items.length === 0) {
                        return this.scanUid()
                    } else {
                        if (this.code == this.basket.code) {
                            return this.finishBasket()
                        } else {
                            return this.getLocationOrUid()
                        }
                    }
                default:
                    this.$vToastify.error('Wrong!');
            }
        },

        async scanBasket() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                const { data } = await httpClient.post('/get-stowing-basket', {
                    code: this.code,
                    id_pos: this.id_pos
                })
                this.basket = { ...data }
                this.code = null
                this.isLoading = false
                this.step = 2
                this.$root.$emit('playSuccessAudio')
                this.stowingSetWorking()
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg)
                this.code = null
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
            }
        },

        async finishBasket() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                const { data } = await httpClient.post('/check-basket-and-finish-stowing', {
                    code: this.code,
                    id_pos: this.id_pos
                })
                this.basket = { ...data }
                this.code = null
                this.isLoading = false
                this.$root.$emit('playSuccessAudio')
                this.resetValue()
                this.updateWorking({
                    type: 'pos_stowing',
                    item: null
                })
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg)
                this.code = null
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
            }
        },

        async getLocationOrUid() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                const { data } = await httpClient.post('/get-location-or-uid', {
                    code: this.code,
                    id_pos: this.id_pos
                })
                this.isLoading = false
                if (data.location) {
                    await this.scanLocation()
                } else if (data.item) {
                    await this.scanUid()
                }
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg)
                this.code = null
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
            }
        },

        async scanLocation() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                const { data } = await httpClient.post('/basket-to-location-stowing', {
                    code: this.code,
                    basket_code: this.basket.code,
                    id_pos: this.id_pos,
                })
                this.location = { ...data }
                this.items = []
                this.location_code = null
                this.code = null
                this.isLoading = false
                this.step = 3
                this.$root.$emit('playSuccessAudio')
                this.stowingSetWorking()
            } catch (e) {
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                if (errMsg === 'max_location_sku') {
                    this.location_code = this.code
                    this.items = []
                    this.location = {}
                    this.$vToastify.warning('SL trong sọt vượt quá SL SKU tối đa của vị trí. Vui lòng gán theo UID')
                    this.code = null
                    this.step = 4
                    this.stowingSetWorking()
                    return false
                }
                this.$vToastify.error(errMsg)
                this.code = null
            }
        },

        async scanUid() {
            if (this.items.includes(this.code) || this.items.includes(this.code.trim())) {
                this.code = null
                this.$vToastify.warning(this.$t('messages.uid_scanned'));
                return false
            }

            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                await httpClient.post('/item-to-location-stowing', {
                    code: this.code,
                    id_pos: this.id_pos,
                    location_code: this.location.cell_id || this.location_code
                })
                this.items.unshift(this.code)
                this.code = null
                this.isLoading = false
                this.$root.$emit('playSuccessAudio')
                this.stowingSetWorking()
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg)
                this.code = null
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
            }
        },
    }
}
</script>

<style scoped></style>